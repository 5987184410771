<template>
  <div>
    <div class="newsbox">
      <div class="newsleft">
        <ul>
          <li v-for="(item, index) in classlist" @click="changelist(item)">
            {{ item.className }}
          </li>
        </ul>
      </div>
      <div class="newsright">
        <div class="righttitle">{{ classname }}</div>
        <div class="list" v-loading="loading">
          <ul>
            <li v-for="(item, index) in list">
              <div class="listleft">
                <router-link
                  target="_blank"
                  :to="{ path: '/user/show', query: { id: item.id } }"
                  >· {{ item.title }}</router-link
                >
              </div>
              <em>{{ item.creationTime }}</em>
            </li>
          </ul>
        </div>
        <div class="page">
          <el-pagination
            layout="prev, pager, next"
            :page-size="size"
            :current-page="page"
            @current-change="changepage"
            @prev-click="prevpage"
            @next-click="nextpage"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userRequest from "@/api/user";
export default {
  data() {
    return {
      page: 1,
      size: 20,
      total: 100,
      classlist: [],
      classid: null,
      classname: "全部新闻",
      loading: false,
      list: [],
    };
  },
  created() {
    let ccid = this.$route.query.cid;
    if (ccid != null && ccid != "") {
      this.classid = this.$route.query.cid;
      this.classname = this.$route.query.t;
    }

    console.log(this.classid);
    this.getclass();
    this.getlist();
  },
  methods: {
    changelist(row) {
      this.classid = row.id;
      this.classname = row.className;
      this.getlist();
    },
    getclass() {
      userRequest.getclassificationList({}).then((res) => {
        this.classlist = res.data;
      });
    },
    getlist() {
      this.loading = true;
      userRequest
        .getnewsList({
          classId: this.classid,
          page: this.page,
          size: this.size,
        })
        .then((res) => {
          this.loading = false;
          console.log(res);
          this.list = res.data.list;
          this.total = res.data.total;
        });
    },
    changepage(e) {
      this.page = e;
      this.getlist();
    },
    nextpage() {
      this.page = this.page + 1;
      let maxpage = Math.ceil(this.total / this.size);
      if (this.page >= maxpage) {
        this.page = maxpage;
      }
      this.getlist();
    },
    prevpage() {
      this.page = this.page - 1;
      if (this.page <= 0) {
        this.page = 1;
      }
      this.getlist();
    },
  },
};
</script>
<style scoped>
.newsbox {
  width: 1198px;
  margin: 0 auto;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
}
.newsleft {
  background: #ffffff;
  width: 250px;
  box-sizing: border-box;
  padding: 15px;
}
.newsleft ul {
  display: block;
  margin: 0;
  padding: 0;
}
.newsleft ul li {
  display: block;
  cursor: pointer;
  overflow: hidden;
  height: 50px;
  line-height: 50px;
  display: block;
  text-align: center;
  border-bottom: #f0f0f0 solid 1px;
}
.newsleft ul li:hover {
  color: #409eff;
}
.newsright {
  background: #ffffff;
  width: 926px;
  box-sizing: border-box;
  padding: 15px;
}
.righttitle {
  font-size: 18px;
  font-weight: bold;
}
.list {
  padding: 15px 0;
  margin-top: 8px;
}
.list ul {
  display: block;
  margin: 0;
  padding: 0;
}
.list ul li {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: #f5f5f5 solid 1px;
}
.list ul li em {
  font-size: 13px;
  color: #999;
  font-style: normal;
}
.listleft {
  font-size: 16px;
}
.list a {
  text-decoration: none;
  color: #444;
}
.list a:hover {
  color: #409eff;
}
.page {
  text-align: center;
  padding: 20px 0;
}

@media screen and (max-width: 800px) {
  * {
    box-sizing: border-box;
  }
  .newsbox {
    width: 100vw;
    margin: 0 auto;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
  }
  .newsleft {
    width: 150px !important;
    padding: 15px 10px;
  }
  .newsright {
    width: 220px;
    padding: 15px;
  }
  .listleft {
    font-size: 12px;
  }
  .list ul li em {
    display: none;
  }
}
</style>